import "@fontsource/dm-mono"
import "@fontsource/dm-mono/300.css"
import "@fontsource/dm-mono/400.css"
import "@fontsource/dm-mono/500.css"
import {AnimatePresence} from "framer-motion";
import React from "react";
import "./src/components/loader/loader.scss";
import Loader from "./src/components/loader/loader";

export const shouldUpdateScroll = ({
                                       routerProps: {location},
                                       getSavedScrollPosition
                                   }) => {
    const TRANSITION_DELAY = 0.5 * 1000
    if (location.action === "PUSH") {
        window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY)
    } else {
        const savedPosition = getSavedScrollPosition(location) || [0, 0]
        window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
    }
    return false
}

export const onClientEntry = () => {
    // setTimeout(() => {
    //     if (document.getElementById("initial-loader")) {
    //         document.getElementById("initial-loader").style.display = "flex";
    //     }
    // }, 0);
};

export const onInitialClientRender = () => {
    // document.body.classList.add("no-scroll-navbar");
    // setTimeout(() => {
    //     if (document.getElementById("initial-loader")) {
    //         document.getElementById("initial-loader").style.display = "flex";
    //         document.body.classList.add("loading-animation");
    //     }
    // }, 0);
};

export const onRouteUpdate = (x) => {
    document.body.classList.remove("no-scroll-navbar");
    // setTimeout(() => {
    //     if (document.getElementById("initial-loader")) {
    //         document.getElementById("initial-loader").style.display = "none";
    //         document.body.classList.remove("no-scroll-navbar");
    //     }
    // }, 6000);
    // setTimeout(() => {
    //     document.body.classList.remove("loading-animation");
    // }, 5500);
};


export const wrapPageElement = ({element, props}) => (
    <>
        {/*<Loader></Loader>*/}
        <AnimatePresence mode="wait">
            {element}
        </AnimatePresence>
    </>
)


